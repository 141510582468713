import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);
const getpage = (name, path = "views") => () => {
	return import(`@/${path}/${name}.vue`);
};
const routes = [
	{
		path: "/",
		components: {
			titlebar: getpage("TitleBar"),
			mainpage: getpage("signinpage"),
			footer: getpage("footer"),
		},
		children: [
			{
				path: "/",
				name: "login",
				components: {
					titlebar: getpage("TitleBar"),
					loginpage: getpage("Login"),
				},
			},
		],
	},
	{
		path: "/auth/:company/:apitoken",
		name: "autologin",
	},
	{
		path: "/iwatch",
		name: "iwatch",
		components: {
			titlebar: getpage("TitleBar"),
			mainpage: getpage("Preview_Login"),
			footer: getpage("footer"),
		},
	},
	{
		path: "/member",
		components: {
			titlebar: getpage("TitleBar"),
			mainpage: getpage("member"),
			footer: getpage("footer"),
		},
		meta: {
			checkmember: true,
		},
		children: [
			{
				path: "prolist",
				beforeEnter: (to, from, next) => {
					next({
						name: "prolist",
					});
				},
			},
			{
				path: "mycourse",
				name: "mycourse",
				components: {
					memberpage: getpage("memberpage/mycourse"),
				},
				meta: {
					titlename: "mycourse",
				},
			},
			{
				path: "memberarea",
				name: "memberarea",
				components: {
					memberpage: getpage("memberpage/memberarea"),
					// footer: getpage("footer")
				},
			},
			// {
			//   path: 'firstpage',
			//   name: 'firstpage',
			//   components: {
			//     titlebar: getpage("TitleBar"),
			//     memberpage: getpage("memberpage/firstpage"),
			//     footer: getpage("footer")
			//   }
			// }, {
			//   path: 'secondpage/:pro',
			//   name: 'secondpage',
			//   components: {
			//     titlebar: getpage("TitleBar"),
			//     memberpage: getpage("memberpage/secondpage"),
			//     footer: getpage("footer")
			//   }
			// },
			{
				path: "thirdpage/:pro",
				name: "thirdpage",
				components: {
					// titlebar: getpage("TitleBar"),
					memberpage: getpage("memberpage/thirdpage"),
					// footer: getpage("footer")
				},
				meta: {
					titlename: "mycourse",
				},
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});
router.afterEach((to, from) => {
	window.scrollTo(0, 1);
});
router.beforeEach(async (to, from, next) => {
	let checkmember = false;
	let cache = true;
	window.prerenderReady = false;
	if (to.name == "login") {
		store
			.dispatch("member/checkmember")
			.then((data) => {
				if (store.state.member.login) {
					next({
						name: "mycourse",
					});
				} else {
					next();
				}
			})
			.catch((err) => {
				// console.log(err)
			});
	}
	// console.log(to.name);
	if (to.name == "autologin") {
		if (to.params.company == process.env.VUE_APP_company) {
		const token = to.params.apitoken.toString().replaceAll("&2FO", "/").replaceAll("&2Z7", "\\");

			localStorage.setItem("token", token);
			next({
				name: "mycourse",
			});
		} else {
			next({
				name: "login",
			});
		}
		return;
	} else if (to.name == null) {
		next({
			name: "login",
		});
	}
	_.each(to.matched, (item, keys) => {
		if (item.meta.checkmember) {
			checkmember = true;
		}
		if (item.meta.noCache) {
			cache = false;
		}
	});
	if (checkmember) {
		store
			.dispatch("member/checkmember")
			.then(async (data) => {
				if (!store.state.member.login) {
					await store.dispatch("member/logout")
					store.dispatch("Public/showToast", {
						title: "■ 您已經登出",
						method: "danger",
						text: "逾期未操作，請再次登入！",
					});
					next({
						name: "login",
					});
				} else {
					next();
				}
			})
			.catch((error) => {
				if (error.response.status == 500) {
					const h = (new Vue).$createElement;
					store.dispatch("Public/showToast", {
						title: "■ 系統逾時",
						method: "danger",
						text:
						h("div", {
							class: "font-up-1"
						  },
						  ["逾期未操作，系統已自動登出，請"
						  	,h("a", {
								class: "text-danger font-weight-bold border-bottom border-danger",
							  attrs: {
								href: 'https://vclass.magee.tw'
							  }
							}, "點擊回首頁"),"重新登入"
						  ]
						)
					});
				} else if (error.response.status == 401) {
					next({
						name: "login",
					});
				}
			});
	} else {
		next();
	}
});

export default router;
