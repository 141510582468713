import Video from '@/service/Video';

export default {
  namespaced: true,
  state: {
    logplay: [],
    logpause: [],
    logwatchtimea: [],
    // logget: [],
    videosaveduration: []
  },
  actions: {
    logplay({
      commit
    }, data) {
      const result = Video.logplay(data.buyuid, data.videouid, data.type, data.logtime, data.proid);
      result.then(data => {
        // console.log(data);
        commit("logplay", data);
      });
      return result;
    },
    logpause({
      commit
    }, data) {
      const result = Video.logpause(data.buyuid, data.videouid, data.type, data.logtime, data.proid);
      result.then((data) => {
        // console.log(data);
        commit("logpause", data);
      });
      return result;
    },
    logwatchtimea({
      commit
    }, data) {
      const result = Video.logwatchtimea(data.buyuid, data.videouid, data.type, data.logtime, data.proid);
      result.then((data) => {
        // console.log(data);
        commit("logwatchtimea", data);
      });
      return result;
    },
    videosaveduration({
      commit
    }, data) {
      const result = Video.videosaveduration(data.element, data.duration, data.proid);
      result.then((data) => {
        // console.log(data);
        commit("videosaveduration", data);
      });
      return result;
    },
  },
  mutations: {
    logplay(state, data) {
      state.logplay = data;
    },
    logpause(state, data) {
      state.logpause = data;
    },
    logwatchtimea(state, data) {
      state.logwatchtimea = data;
    },
    videosaveduration(state, data) {
      state.videosaveduration = data;
    },
  }
}

// logget(state, data) {
//   state.logget = data;
// },

// resetttingdata(pl, s) {
//   const poststate = (this.$route.params.buyuid) ? this.$route.params.buyuid : sessionStorage[
//     "buyuid"];
//   const videouid = (this.$route.params.videouid) ? this.$route.params.videouid : sessionStorage[
//     "videouid"];
//   const proid = (this.$route.selectpro) ? this.$route.selectpro : sessionStorage["selectpro"];
//   return {
//     buyuid: poststate,
//     videouid: videouid,
//     type: pl,
//     logtime: s,
//     proid: proid
//   }
// },

// logget({
//   commit
// }, data) {
//   const result = Video.logget(eleuid, func);
//   result.then((data) => {
//     console.log(data);
//     commit("logget", data);
//   });
//   return result;
// },
