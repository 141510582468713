import _ from "lodash";
import Vue from "vue";

export default {
	namespaced: true,
	state: {
		loading: false,
		navigatorInfo: {
			rtt: 0,
			downlink: 0,
			effectiveType: "",
			saveData: 0,
			online: false,
		},
	},
	getters: {
		getLocalStore(state, getters, $rootState) {
			const member = $rootState.member.Login.uid;
			// console.log(localStorage[member], $rootState.member.Login);
			if (member && localStorage[member]) {
				return JSON.parse(localStorage[member]);
			} else {
				return {};
			}
		},
	},
	actions: {
		showToast({ commit }, { title, method, text, time, toaster }) {
			new Vue().$bvToast.toast(text, {
				title: title,
				variant: method || "info",
				toaster: toaster || "b-toaster-top-center",
				solid: true,
				// noAutoHide:true
				autoHideDelay: time || 5000,
			});
		},
		openLoading({ commit }, data) {
			commit("setLoading", true);
			return data;
		},
		closeLoading({ commit }) {
			commit("setLoading", false);
		},
		setLocalStore({ dispatch, commit, getters, rootState }, data) {
			// console.log(rootState, getters.getLocalStore);
			const thmemberstore = getters.getLocalStore;
			const member = rootState.member.Login.uid;
			_.each(data, (item, key) => {
				thmemberstore[key] = item;
			});
			const setdata = JSON.stringify(thmemberstore);
			if (member && localStorage[member]) {
				localStorage[member] = setdata;
			} else {
				localStorage.setItem(member, setdata);
			}
			// if { }
		},
	},
	mutations: {
		setLoading(state, data) {
			state.loading = data;
		},
		setNavigator(state, { rtt, downlink, effectiveType, saveData }) {
			state.navigatorInfo = {
				rtt: rtt,
				downlink: downlink,
				effectiveType: effectiveType,
				saveData: saveData,
				online: navigator.onLine,
			};
		},
	},
};
