import pro_Class from '@/service/proClass';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    coursetitle: [],
    course: [],
    unit: [],
    mediainformation: [],
    // mediatab: [],
    gomedia: {
      product_parents: "",
      name: "",
      watchnumber: 0,
      resttime: "",
      lasttime: "",
      mainsrc: "",
      nytimes: "",
    },
    prolist: [],
    unitlist: [],
    getmember: [],
    MediaModalMSG: [],

  },
  getters: {
    // firstpage
    getshowpro(state) {
      return (data,where) => {
        let uid = null;
        if (data) {
          uid = data;
        } else {
          let v = [];
          _.each(state.prolist, (item, keys) => {
            _.each(item, (item, keys) => {
              v.push(item);
            })
          })

          return _.orderBy(v, [where.field],[where.order]);
        }
        if (Object.keys(state.prolist).length == 0) {
          return [];
        }
        // console.log(state.prolist);
        const res = _.orderBy(state.prolist[uid].map((item, key) => {

          const tmpObject = {};
          Object.keys(item).forEach((keys) => {
            tmpObject[keys] = item[keys];
          })
          tmpObject.visiable = false;
          return tmpObject;
        }), [where.field], [where.order]);
        // console.log(res);
        return res;
      }
    },
    getshowunit(state) {
      return (data) => {
        let uid = null;
        if (data) {
          uid = data;
        } else if (state.unit.length > 0) {
          uid = state.unit[0].uid;
        } else {
          return [];
        }
        return state.unitlist[uid];
      }
    },
    GetMediaModalMSG(state) {
      return (data) => {
        state.MediaModalMSG = data;
        return state.MediaModalMSG;
      }
    }
  },
  actions: {
    deductTime({}, data) {
      const result = pro_Class.deductTime(data.buyuid, data.uid, data.mainuid, data.proid);
      return result;
    },
    // 獲取課程標題、資訊
    getcoursetitle({
      commit
    }) {
      const result = pro_Class.product_class();
      result.then((data) => {
        commit("setcoursetitle", data.data.outClass);
      });
      return result;
    },
    // 獲取課程數量、資訊
    getcourse({
      commit
    }, {
      searchmethod,
      date
    }) {
      let prolist = [];

      const result = pro_Class.product_class(searchmethod);
      result.then((data) => {
        let prolistdata = data.data.pro;
        if (data.data.outClass.length > 0) {
          prolist = prolistdata[data.data.outClass[0].uid];
        } else {
          prolist = [];
        }
        let pro_List = {
          pro: prolistdata,
          prolist: prolist
        };
        // console.log(data.data)
        commit("setcoursetitle", data.data.outClass);
        commit("setcourse", pro_List);
      });
      return result;
    },
    // 獲取單元標題、資訊
    subprolist({
      commit
    }, {buylist, pro}) {
      const result = pro_Class.subprolist(buylist,pro);
      result.then((data) => {
        commit("setunit", data.data.tagmenu);
      });
      return result;
    },
    // 獲取單元影片數量、資訊
    getmediainformation({
      commit
    }, data) {
      const result = pro_Class.getproElement(data.watchuid, data.prouid);
      // commit("setmediainformation", []);
      result.then((data) => {
        commit("setmediainformation", data.data);
        // commit("setmediatab", data.data);
      });
      return result;
    },
    // getmediatab({
    //   state,
    //   commit
    // }) {
    //   commit("setmediatab", state.mediainformation);
    // },
    getvideo({
      commit
    }, data) {
      const result = pro_Class.getvideo(data.buyuid, data.uid, data.mainuid, data.proid);
      result.then(data => {
        let setdata = data.data;
        if (data.data.errorout) {
          setdata = {
            product_parents: "",
            name: "",
            watchnumber: 0,
            resttime: "",
            lasttime: "",
            mainsrc: "",
            nytimes: "",
            changeMethod: 0
          }
        }

        commit("setgomedia", setdata);
      });
      return result;
    },
    getmember({
      commit
    }) {
      const result = pro_Class.getmember();
      result.then(data => {
        commit("getmember", data.data.s);
      });
      return result;
    }
  },
  mutations: {
    setcoursetitle(state, data) {
      // console.log(data);
      state.coursetitle = data;
    },
    setcourse(state, data) {
      state.course = data.prolist;
      // console.log(data.prolist);
      if (data.pro) {
        state.prolist = data.pro;
      }
    },
    setunit(state, data) {
      state.unit = data;
    },
    setmediainformation(state, data) {
      // console.log(data);
      state.mediainformation = data;
      // state.mediatab = data;
    },
    // setmediatab(state, data) {
    //   console.log(data);
    //   state.mediatab = data;
    // },
    setgomedia(state, data) {
      _.each(data, (item, keys) => {
        state.gomedia[keys] = item;
      })
      // state.gomedia = data;
    },
    getmember(state, data) {
      state.getmember = data;
    }
  }
}
