import mem from '@/service/member';

export default {
  namespaced: true,
  state: {
    login: false,
    name: "",
    getUserInformation: [],
    OpenAccount: false,
    Login: {
      isLogin: false,
      account: "",
      api_token: "",
      email: "",
      homephone: "",
      id: "",
      location: "",
      name: "",
      open: "",
      phone: "",
      postelcode: "",
      uid: ""
    }
  },
  actions: {
    iwatchlogin({
      commit
    }) {
      const result = mem.iwatchlogin();
      result.then(data => {
        commit("setUser", {
          status: true,
          name: data.data.data.name,
          openAccount: true
        });
      });
      return result;
    },
    login({
      commit
    }, data) {
      const result = mem.login(data.account, data.password, data.vehicle);
      result.then(data => {
        localStorage.setItem("token", data.data.token);
        commit("setUser", {
          status: true,
          name: data.data.name,
          OpenAccount: false
        });
      });
      return result;
    },
    logout({
      commit
    }) {
      localStorage.token = "";
      sessionStorage.token = "";
      commit("setmember", {
        Login: false,
        data: {
          status: false,
          name: ""
        }
      });

    },
    getinformation({
      commit
    }) {
      const result = mem.getinformation();
      result.then(data => {
        commit("setUserInformation", data.data);
      })
      return result;
    },
    checkmember({
      commit
    }) {
      const result = mem.checkmember();
      result.then((data) => {
        // console.log(data);
        commit("setmember", {
          Login: data.data.state,
          data: data.data.s
        });
      }).catch(() => {

      })
      return result;
    },
  },
  mutations: {
    setUser(state, {
      status,
      name,
      openAccount
    }) {
      state.login = status;
      state.name = name;
      state.openAccount = openAccount
    },
    setUserInformation(state, data) {
      state.getUserInformation = data;
    },
    setmember(state, data) {
      _.each(state.Login, (item, keys) => {
        if (keys != "isLogin") {
          state.Login[keys] = data.data[keys];
        }
      });

      state.login = data.Login;
    }
  }
}
